<template>
  <v-card color="grey lighten-4" v-if="round" :key="round.id" class="pt-1" tile>
    <v-container fluid  v-if="view.public && !round.published">
      <h2 class="ma-12 text-center">
        <v-avatar color="red">
          <v-icon color="white">fas fa-eye-slash</v-icon>
        </v-avatar>
        <br class="hidden-md-and-up">
        This schedule has not been published yet.
      </h2>
    </v-container>

    <v-container v-else fluid class="pt-0" :class="{'px-2': $vuetify.breakpoint.xsOnly, 'pb-0': view.adminAndRefs}">
      <v-alert
        :value="view.admin && !round.published && round.flights.length > 0"
        transition="scale-transition"
        type="warning"
        prominent tile
        class="mb-0"
      >
          <v-row align="center">
            <v-col class="grow">
              <strong class="black--text">{{division.name}} {{round.name}} is not published. </strong>
            </v-col>
            <v-col class="shrink">
              <v-btn
                @click.stop="publish"
                :loading="loading"
              >publish</v-btn>
            </v-col>
          </v-row>
      </v-alert>
      <v-alert
        type="warning"
        prominent
        :value="view.adminAndRefs && round.pools.length === 1 && pool.complete && !pool.locked"
        transition="scale-transition"
      >
        <v-row align="center">
          <v-col class="grow">
            <strong class="black--text">{{round.name}} appears complete. Lock it to prevent accidental changes.</strong>
          </v-col>
          <v-col class="shrink">
            <v-btn
              @click.stop="onLockToggle"
              :loading="loading"
              class="ml-3"
            >lock</v-btn>
          </v-col>
        </v-row>
      </v-alert>
      <!-- Matchup Matrix -->
      <v-row dense v-if="view.adminAndRefs">
        <v-col cols="12" class="text-end">
          <v-btn color="color3" x-small text @click.stop="showMatrix = !showMatrix">{{showMatrix ? 'hide' : 'show'}} matchup matrix</v-btn>
          <v-btn
            color="color3Text color3--text"
            class="mr-1"
            fab
            small
            @click.stop="print"
            v-if="view.adminAndRefs"
          >
            <v-icon small>fas fa-print</v-icon>
          </v-btn>
        </v-col>
        <v-expand-transition>
          <v-col cols="12" v-if="showMatrix">
            <matchup-matrix
              v-if="!division.isKob"
              :matches="matches"
              :division="division"
            ></matchup-matrix>
            <kob-matchup-matrix
              v-else
              :matches="round.poolMatches"
              :division="division"
            ></kob-matchup-matrix>
          </v-col>
        </v-expand-transition>
      </v-row>
      <!-- Bye alert -->
      <v-row dense v-if="division.hydrated && byeTeams.length">
        <v-col cols="12">
          <v-alert type="info" text prominent>
            <div><b>Teams with byes:</b></div>
            <ul>
              <li v-for="team in byeTeams" :key="team.id">
                {{team.name}}
              </li>
            </ul>
          </v-alert>
        </v-col>
      </v-row>
      <template v-if="round.pools.length === 1 && !division.isKob">
        <!-- Match style alert -->
        <v-row dense id="match-style">
          <v-col cols="12">
            <v-alert
              type="info"
              :class="$vuetify.breakpoint.smAndDown ? 'body-2' : 'subtitle-1'"
            >
              All matches are {{pool.matchDescription}}.
            </v-alert>
          </v-col>
        </v-row>
        <!-- Record all -->
        <v-row dense justify="end" v-if="view.adminAndRefs && !pool.locked">
          <edit-all-dialog :pool="pool"></edit-all-dialog>
        </v-row>
        <!-- Matches -->
        <v-row dense id="first-match">
          <template v-if="useBracketCard">
            <v-col cols="12" md="6" v-for="(match) in matches" :key="`${match.poolId}_${match.number}`">
              <bracket-match-card
                :matchIn="match"
                :pool="pool"
                :teamColors="teamColors"
              ></bracket-match-card>
            </v-col>
          </template>
          <template v-else-if="pool.isDuals">
            <v-col cols="12" md="6" v-for="(dual) in pool.duals" :key="`${pool.id}_${dual.n}`">
              <dual-card
                :dual="dual"
                :pool="pool"
                :myKey="pool.id"
                :round="round"
                :division="division"
              ></dual-card>
            </v-col>
          </template>
          <template v-else>
            <v-col cols="12" md="6" v-for="(match) in matches" :key="`${match.poolId}_${match.number}`">
              <match-card
                :matchIn="match"
                :pool="pool"
                :round="round"
                :division="division"
              ></match-card>
            </v-col>
          </template>
        </v-row>
        <!-- Locak As Is -->
        <v-row dense v-if="view.adminAndRefs && !pool.locked">
          <v-col cols="12" class="text-end pt-3">
            <v-btn
              color="color3"
              text
              small
              @click.stop="onLockToggle"
              :loading="loading"
          >Lock As Is</v-btn>
          </v-col>
        </v-row>
      </template>
      <template v-else>
        <v-row dense>
          <v-col cols="12" class="text-end">
            <v-btn color="color3" text x-small @click.stop="showPools = !showPools">{{showPools ? 'Hide' : 'Show'}} Pools</v-btn>
          </v-col>
          <v-col cols="12" v-if="showPools">
            <flight-card
              v-for="(flight) in round.flights"
              :key="`flight_${flight.id}`"
              :flight="flight"
              :isLeague="true"
            ></flight-card>
          </v-col>
        </v-row>
        <v-row dense v-if="!showPools">
          <v-col cols="12" md="6" v-for="pool in round.pools" :key="pool.id">
            <pool-sheet :_poolId="pool.id" :_dayId="round.id"></pool-sheet>
            <div class="caption" v-if="user && user.vbl">Pool Id: {{pool.id}}</div>
          </v-col>
        </v-row>
      </template>
    </v-container>
  </v-card>
</template>

<script>
// import MatchCard from '@/components/Tournament/Match/MatchCard.vue'
import MatchCard from '@/components/Tournament/Match/FullMatchCard.vue'
import BracketMatchCard from '@/components/Tournament/Match/BracketMatchCard.vue'
import EditAllDialog from '@/components/Tournament/Pools/EditAllDialog.vue'
import firstBy from 'thenby'
import { mapGetters } from 'vuex'
const DualCard = () => import('@/components/Tournament/Duals/DualCard.vue')
const MatchupMatrix = () => import('@/components/Leagues/MatchupMatrix.vue')
const KobMatchupMatrix = () => import('@/components/Leagues/KobMatchupMatrix.vue')
const PoolSheet = () => import('@/components/Tournament/Pools/PoolSheet/Index.vue')
const FlightCard = () => import('@/components/Tournament/Pools/FlightCard.vue')

export default {
  props: ['division', 'round'],
  data () {
    return {
      advanced: false,
      useBracketCard: false,
      dialog: false,
      editAll: false,
      loading: false,
      summaries: false,
      hideSum: false,
      display: 'court',
      showMatrix: false,
      matches: [],
      showPools: false
    }
  },
  computed: {
    ...mapGetters([
      'admin',
      'view',
      'getDivision',
      'teamColors',
      'teamColorsInverse',
      'tournament',
      'getTeam',
      'user'
    ]),
    byeTeams () {
      return this.division.activeTeams.filter(f => !this.round.activeTeamIds.includes(f.id))
    },
    pool () {
      return this.round.pools.length && this.round.pools[0]
    },
    lockDTO () {
      return this.pool.teams.map(team => {
        return {
          id: team.id,
          summary: {
            finish: team.rank,
            dualWins: team.dualWins,
            dualLosses: team.dualLosses,
            matchWins: team.matchWins,
            matchLosses: team.matchLosses,
            setWins: team.setWins,
            setLosses: team.setLosses,
            pointsFor: team.pointsFor,
            pointsAgainst: team.pointsAgainst
          }
        }
      })
    },
    matchCount () {
      return (this.pool ? this.pool.matches.length : 0) + this.tournament.crossDivMatches.length
    }
  },
  methods: {
    publish () {
      this.loading = true
      this.round.patch({
        published: true
      })
        .then(() => { this.round.published = true })
        .catch(err => { console.log(err.response) })
        .finally(() => { this.loading = false })
    },
    teamClick (id) {
      this.$router.push({ name: 'division-team', params: { tournamentId: this.tournament.id, teamId: id } })
    },
    onFinishManually (finishes) {
      this.lockToggle(finishes)
    },
    onLockToggle () {
      this.lockToggle()
    },
    lockToggle (manualFinishes) {
      this.loading = true
      const dto = {
        locked: !this.pool.locked,
        teams: this.lockDTO
      }
      if (manualFinishes) {
        manualFinishes.forEach(finish => {
          const team = dto.teams.find(f => f.id === finish.id)
          team.summary.finish = +finish.finish
          team.summary.matchWins = +finish.matchWins
          team.summary.matchLosses = +finish.matchLosses
          team.summary.pointsFor = +finish.pointsFor
          team.summary.pointsAgainst = +finish.pointsAgainst
        })
      }
      this.pool.patch(dto)
        .then(() => {
          this.pool.locked = dto.locked
          dto.teams.forEach(t => {
            const team = this.pool.teams.find(f => f.id === t.id)
            team.summary = t.summary
          })
          this.loading = false
        })
    },
    print () {
      window.print()
    },
    updateMatches () {
      const cds = this.tournament.crossDivMatches.filter(f => f.title.endsWith(`-${this.round.id}`))
      const pms = this.pool ? this.pool.matches : []
      this.matches = [...pms, ...cds].sort(firstBy('unix').thenBy('court'))
      // console.log(this.matches.length)
    }
  },
  watch: {
    matchCount: 'updateMatches'
  },
  components: {
    MatchCard,
    EditAllDialog,
    DualCard,
    BracketMatchCard,
    MatchupMatrix,
    PoolSheet,
    KobMatchupMatrix,
    FlightCard
  },
  mounted () {
    // console.log(this.tournament.crossDivMatches)
    if (this.round.name === 'Matches') {
      this.hideSum = true
    }
    // this.pool && this.pool.matches.sort(firstBy('unix').thenBy('court'))
    this.updateMatches()
  }
}
</script>

<style scoped>
.sm {
  font-size: smaller !important;
}
.xs {
  font-size: x-small;
}
.forfeit {
  text-decoration: line-through;
}
.v-avatar span {
  font-size: 13px;
}
.nested td {
  border: none !important;
}
.nested td > div {
  line-height: initial;
}
</style>
