<template>
  <v-container fluid class="pa-0">
    <template v-if="division.days.length">
      <v-tabs
        background-color="color3"
        slider-color="color3Text"
        active-class="color3Text color3--text"
        class="color3 color3Text--text"
        show-arrows
        center-active
        centered
        v-model="tab"
      >
        <v-tab
          v-for="round in rounds"
          :key="round.id"
          :class="qRound === round.number ? 'color3--text' : 'color3Text--text'"
        >
          {{round.name}}
          <v-icon v-if="round.leagueLock" class="pl-1" small :color="qRound === round.number ? 'color3' : 'color3Text'">fas fa-lock</v-icon>
          <v-icon v-if="view.admin && !round.published" class="pl-1" small :color="qRound === round.number ? 'color3' : 'color3Text'">fas fa-eye-slash</v-icon>
        </v-tab>
      </v-tabs>
      <v-row dense>
        <v-spacer></v-spacer>
        <v-btn v-if="false" color="color3" text class="xsmall my-0" :class="{'mx-0': xs}" @click.stop="courts = !courts">toggle {{courts ? 'names' : 'courts'}}</v-btn>
      </v-row>
        <v-tabs-items
          v-model="tab"
        >
          <v-tab-item
            v-for="round in rounds"
            :key="round.id"
          >
            <v-card :class="{'ma-3': !xs}">
              <v-toolbar color="color1 color1Text--text">
                <v-toolbar-title>{{round.name}}  <span class="caption">{{round.date.format('MMM Do')}}</span></v-toolbar-title>
                <v-spacer></v-spacer>
                <unlock-dialog
                  v-if="view.adminAndRefs && round.leaguePool && round.leaguePool.locked && !tournament.locked"
                  @unlock-click="onLockToggle(round.leaguePool)"
                  :inline="true"
                  :warn="false"
                ></unlock-dialog>
              </v-toolbar>
              <v-card-text :class="{'pa-0': xs}">
                <regular-season-matches
                  :division="division"
                  :round="round"
                ></regular-season-matches>
                <template v-if="view.adminAndRefs">
                  <div class="xsmall">Round Id: {{round.id}}{{round.leaguePool ? ` Pool Id: ${round.leaguePool.id}` : '' }}</div>
                  <v-btn
                    color="color3"
                    text
                    @click.stop="clearRound = true"
                    :loading="clearing"
                  >Clear Round</v-btn>
                  <vbl-confirm-dialog
                    :ask="clearRound"
                    question="Delete all matches?"
                    :loading="clearing"
                    @confirm="() => { clearIt(round.id) }"
                    @deny="clearRound = false"
                  ></vbl-confirm-dialog>
                  <template v-if="showAddRefs(round.leaguePool)">
                    <v-btn
                      color="color3"
                      text
                      @click.stop="addRefs = true"
                      :loading="addingRefs"
                    >Add Refs</v-btn>
                    <vbl-confirm-dialog
                      :ask="addRefs"
                      question="Add a ref to matches without one?"
                      :loading="addingRefs"
                      @confirm="() => { goAddRefs(round.leaguePool.id) }"
                      @deny="addRefs = false"
                    ></vbl-confirm-dialog>
                  </template>
                </template>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
    </template>

  </v-container>
</template>

<script>
import RouteMixin from '@/Mixins/RouteMixin'
import firstBy from 'thenby'
import { mapGetters } from 'vuex'
import RegularSeasonMatches from './RegularSeasonMatches'
import moment from 'moment'
const UnlockDialog = () => import('@/components/Tournament/Pools/LockToggle.vue')

export default {
  mixins: [RouteMixin],
  data () {
    return {
      loading: false,
      tab: null,
      courts: false,
      clearRound: false,
      clearing: false,
      addRefs: false,
      addingRefs: false
    }
  },
  computed: {
    ...mapGetters(['getDivision', 'admin', 'view', 'user', 'tournament']),
    xs () {
      return this.$vuetify.breakpoint.xsOnly
    },
    division () {
      return this.getDivision(this.divisionId)
    },
    rounds () {
      if (!this.division) return null
      const j = this.tournament.jProps
      let r = this.division.leaguePlayRounds
      const props = j && j.rounds && j.rounds
      if (props) {
        r = r.filter(f => !(props[f.id] && props[f.id].tournamentStyle))
      }

      return r
    },
    pools () {
      return this.rounds && this.rounds.map(m => m.leaguePool)
    },
    tabs () {
      const tabs = []
      if (this.division.leaguePlayRounds.length > 0) {
        this.division.leaguePlayRounds.forEach(day => {
          tabs.push({
            text: day.name,
            icon: day.leaguePool.locked ? 'fas fa-lock' : null,
            i: day.number
          })
        })
      }
      return tabs.sort(firstBy('i'))
    },
    qRound () {
      return +this.$route.query.round
    }
  },
  methods: {
    setTab () {
      if (this.$route.query.round) {
        this.tab = +this.$route.query.round - 1
      } else {
        var i = this.division.leaguePlayRounds.findIndex(f => f.date.isSameOrAfter(moment(), 'day'))
        if (i === -1) i = this.division.leaguePlayRounds.length - 1
        this.tab = i
        this.$router.replace({ query: { round: i + 1 } })
      }
    },
    clearIt (id) {
      this.clearing = true
      this.$VBL.tournament.league.clear(id)
        .then(r => { this.clearRound = false })
        .catch(e => console.log('error'))
        .finally(() => {
          this.clearing = false
        })
    },
    goAddRefs (poolId) {
      this.addingRefs = true
      this.$VBL.tournament.league.addRefs(this.tournament.id, this.divisionId, poolId)
        .then(r => { this.addRefs = false })
        .catch(e => console.log('error'))
        .finally(() => {
          this.addingRefs = false
        })
    },
    onLockToggle (pool) {
      pool.patch({ locked: false })
        .then(() => {
          this.pool.locked = false
          this.loading = false
        })
    },
    showAddRefs (pool) {
      return pool && pool.matches.filter(f => !f.refTeam && !f.manualRefId && !f.manualRef).length > 0
    }
  },
  watch: {
    tab (val) {
      if (val > -1 && val !== +this.$route.query.round - 1) {
        this.$router.push({ query: { round: val + 1 } })
      }
    }
  },
  mounted () {
    this.setTab()
  },
  components: {
    RegularSeasonMatches,
    UnlockDialog
  }
}
</script>

<style scoped>
</style>
