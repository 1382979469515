<template>
  <v-dialog v-model="dialog" max-width="500px" scrollable :persistent="loading">
    <template v-slot:activator="{ on }">
      <v-btn v-on="on" color="color3" text small>
        <v-icon class="mr-4">fas fa-microphone</v-icon>Record All Matches
      </v-btn>
    </template>
    <v-card color="grey lighten-3">
       <v-toolbar color="color2 color2Text--text">
         <v-toolbar-title>Record Matches</v-toolbar-title>
       </v-toolbar>
      <v-card-text class="pt-2" :class="{ 'px-2': !$vuetify.breakpoint.xsOnly, 'px-1': $vuetify.breakpoint.xsOnly }">
        <match-card
          class="mb-4"
          :matchIn="match"
          :skinny="true"
          :editAll="dialog"
          :key="'match_' + match.number"
          :ref="'match_' + match.number"
          v-for="(match, i) in matches"
          :editAllN="i"
          :pool="pool"
          @dirty-change="onDirtyChange"
          :round="round"
          :division="division"
        ></match-card>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="dirtyN.length === 0"
          :loading="loading"
          color="color3 color3Text--text"
          @click.stop="saveAll"
        >
          Save
        </v-btn>
        <v-btn
          @click.stop="dialog = false"
          :disabled="loading"
        >
          Cancel
        </v-btn>
        <v-btn
          @click.stop="test"
          :disabled="loading"
          v-if="false"
        >
          test
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// import MatchCard from '@/components/Tournament/Match/MatchCard.vue'
import MatchCard from '@/components/Tournament/Match/FullMatchCard.vue'
import firstBy from 'thenby'

export default {
  props: ['pool', 'round', 'division'],
  data () {
    return {
      dialog: false,
      loading: false,
      dirtyN: []
    }
  },
  computed: {
    matches () {
      return this.pool.matches
    },
    dirtyMatches () {
      return this.dirtyN.map(n => {
        console.log('e')
        const ref = this.$refs[`match_${n}`]
        return ref && ref[0].dto
      }).filter(f => !!f)
    }
  },
  methods: {
    saveAll () {
      if (this.dirtyN.length === 0) return

      this.loading = true
      var dto = this.getDtos()
      this.$VBL.post.matches(dto)
        .catch(err => {
          console.log(err.response)
        })
        .finally(() => {
          this.loading = false
          this.dialog = false
        })
    },
    onDirtyChange (payload) {
      if (payload.dirty) {
        this.dirtyN.push(payload.n)
      } else {
        this.dirtyN = this.dirtyN.filter(f => f !== payload.n)
      }
    },
    getDtos () {
      return this.dirtyN.map(n => {
        console.log('e')
        const ref = this.$refs[`match_${n}`]
        const b = ref[0]
        return b && b.dto
      }).filter(f => !!f)
    },
    test () {
      console.log(this.getDtos())
    }
  },
  watch: {
    dialog: function (val) {
      if (this.tournamentId === 80) {
        if (val) {
          const order = [1, 2, 3, 7, 8, 10, 9, 6, 5, 4]
          return this.pool.matches.sort(firstBy(m => {
            return order.indexOf(m.number)
          }))
        } else {
          this.pool.matches.sort(firstBy('number'))
        }
      }
    }
  },
  components: {
    'match-card': MatchCard
  }
}
</script>

<style>

</style>
